<template>
  <div v-if="options" class="flex gap-4">
    <lf-dropdown
      :value="options.search_tier"
      :options="searchTierOptions"
      full-width
      @change="options.search_tier = $event as SelectedOptions['search_tier']"
    />
    <lf-dropdown
      :value="options.search_type"
      :options="searchTypeOptions"
      full-width
      @change="options.search_type = $event as SelectedOptions['search_type']"
    />
    <lf-dropdown
      :value="options.is_marketing_use"
      :options="isMarketingUseOptions"
      full-width
      @change="
        options.is_marketing_use = Number(
          $event
        ) as SelectedOptions['is_marketing_use']
      "
    />
  </div>
</template>
<script setup lang="ts">
import LfDropdown from "@/components/ui/inputs/LfDropdown.vue";
import type { SelectedOptions } from "@/models/commercialData/lexisnexis/LexisNexisEmailSearchRequest";
import { useI18n } from "vue-i18n";

const options = defineModel<SelectedOptions>();

const { t } = useI18n();

const searchTierOptions: Record<SelectedOptions["search_tier"], string> = {
  Basic: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.SEARCH_TIER.BASIC"),
  Premium: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.SEARCH_TIER.PREMIUM")
} as const;

const searchTypeOptions: Record<SelectedOptions["search_type"], string> = {
  EAA: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.SEARCH_TYPE.EMAIL_ADDRESS_APPEND"),
  EIA: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.SEARCH_TYPE.EMAIL_IDENTITY_APPEND"),
  EIC: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.SEARCH_TYPE.EMAIL_IDENTITY_CHECK")
} as const;

const isMarketingUseOptions: Record<
  SelectedOptions["is_marketing_use"],
  string
> = {
  0: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.MARKETING_USE.FALSE"),
  1: t("DEALS.LEXIS_NEXIS.EMAIL_SEARCH.MARKETING_USE.TRUE")
} as const;
</script>
